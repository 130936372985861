import { wechatConfig } from '@/common/wx'

export default {
  data() {

  },
  computed: {
    globalData() {
      return this.$store.getters.globalData
    }
  },
  created() {
    // 注入微信JSSDK配置信息
    // wechatConfig()
    //   .then(() => {
    //     this.wechatConfigSuccessCallback() // 微信签名成功Ready
    //   })
    //   .catch(err => {
    //     console.error(err)
    //   })
  },
  methods: {
    wechatConfigSuccessCallback() {

    },
    onNavigator({ path, query }) {
      // tab 页面未作处理，勿用
      this.$router.push({ path, query })
    }
  }
}
