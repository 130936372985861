<template>
    <div>
        <van-overlay :show="loading" />
        <van-loading class="loading" v-if="loading" color="#00cca2" />
    </div>
</template>

<script>
import { Overlay } from 'vant'
    export default {
        name:'loadings',
        props:{
            loading:Boolean
        },
        components:{
            [Overlay.name]: Overlay,
        }
    }
</script>

<style lang="less" scoped>
    .loading{
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 10;
        margin-left: -20px;
    }
</style>